import { ref, computed, onUnmounted } from 'vue';

const BREAKPOINTS = {
	MOBILE: 767,
	TABLET: 995,
	DESKTOP: 1135
};
export const useIsMobile = () => {
	const viewportWidth = ref(null);

	const setViewportWidth = () => {
		viewportWidth.value = window.innerWidth;
	};

	const isMobile = computed(() => viewportWidth.value <= BREAKPOINTS.MOBILE);
	const isTablet = computed(() => viewportWidth.value > BREAKPOINTS.MOBILE && viewportWidth.value <= BREAKPOINTS.TABLET);
	const isDesktop = computed(() => viewportWidth.value > BREAKPOINTS.TABLET && viewportWidth.value <= BREAKPOINTS.DESKTOP);
	const isLargeDesktop = computed(() => viewportWidth.value > BREAKPOINTS.DESKTOP);

	window.addEventListener('resize', setViewportWidth);
	setViewportWidth();

	onUnmounted(() => {
		window.removeEventListener('resize', setViewportWidth);
	});

	return {
		isMobile,
		isTablet,
		isDesktop,
		isLargeDesktop
	};
};
